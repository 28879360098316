<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.update_district") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-form
            ref="form"
            status-icon
            :model="form"
            :rules="rules"
            class="aticler_m"
          >
            <el-row :gutter="20">
              <el-col :sm="12">
                <el-form-item :label="columns.name.title" prop="name">
                  <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="12">
                <el-form-item :label="columns.city_id.title">
                  <el-select
                    v-model="form.city_id"
                    :placeholder="columns.city_id.title"
                    filterable
                    clearable
                    remote
                    :remote-method="searchCitiesWithLoad"
                    :loading="loadingData"
                  >
                    <el-option
                      v-for="item in cities"
                      :key="item.id + item.name"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import _ from "lodash";
export default {
  mixins: [form],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      loadingData: false,
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  async mounted() {
    if (this.cities && this.cities.length === 0)
      await this.loadCities({ selected_id: this.form.city_id });
  },
  computed: {
    ...mapGetters({
      rules: "districts/rules",
      model: "districts/model",
      columns: "districts/columns",
      cities: "cities/inventory",
    }),
  },
  methods: {
    ...mapActions({
      save: "districts/update",
      loadCities: "cities/inventory",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
      this.loadCities({ selected_id: this.form.city_id });
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$emit("c-close", { drawer: "drawerUpdate" });
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              // this.parent().listChanged();
              this.$refs["form"].resetFields();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    searchCitiesWithLoad(val) {
      this.loadingData = true;
      this.searchCities(val);
    },
    searchCities: _.debounce(function (val) {
      this.loadCities({ search: val })
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    }, 500),
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
  },
};
</script>
